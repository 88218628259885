<template>
  <v-card elevation="0" class="transparent">
    <v-chip-group
      v-if="getWindowsForDate().length && !winsLoading" v-model="chip"
      active-class="teal white--text" column class="mt10"
    >
      <v-row justify="center" class="mx-1">
        <v-chip
          large close
          v-for="win in getWindowsForDate()"
          :key="win._id"
          @click:close="deleteWindowHandler(win)"
        >{{ win.time }}
        </v-chip>
      </v-row>
    </v-chip-group>

    <v-card-text
      v-else-if="!getWindowsForDate().length && !winsLoading"
      class="teal--text text-center"
    >
      На выбранную дату нет окон
    </v-card-text>

    <v-row v-if="winsLoading" justify="center" class="my-10">
      <v-progress-circular indeterminate color="teal"></v-progress-circular>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: "Wins",
    props: ["recordList", "date", "winsLoading", "setTime", "loadRecordList", "setWinsLoading"],
    data: () => ({
      chip: undefined,
      time: undefined
    }),

    computed: {
      wins() {
        if (this.recordList.length) {
          return this.recordList.filter(record => record.open)
        }
        return []
      },
      // windowsForDate() {
      //   const ws = []
      //   this.wins.forEach(w => {
      //     if (this.date === w.date) ws.push(w)
      //   })
      //   return ws
      // }
    },

    methods: {
      async deleteWindowHandler(w) {
        if (!window.confirm("Вы уверены что хотите удалить окно?")) {
          return
        }

        const {date, time} = w
        this.setWinsLoading(true)
        const json = await this.authFetch('/record/delete-window', {time, date})
        this.setWinsLoading(false)
        if (json.status === 200) this.loadRecordList()
      },

      getWindowsForDate() {
        const ws = []
        this.wins.forEach(w => {
          if (this.date === w.date) ws.push(w)
        })
        return ws
      },
    },

    watch: {
      date: async function() {
        this.chip = undefined
        if (await this.getWindowsForDate().length === 1) this.chip = 0
      },

      chip: function(c) {
        if (c !== undefined) this.time = this.getWindowsForDate()[c].time
        else this.time = undefined
      },

      time: function(t) {
        if (t) {
          this.setTime(t)
        } else {
          this.setTime(undefined)
        }
      }
    }
  }
</script>

<style scoped>

</style>
