<template>
  <div>
    <v-btn
      max-width="374" min-width="320px" small :color="text ? 'blue darken-2': 'blue'"
      dark
      :text="text"
      @click="dialog = true"
    >
      Зарегистрировать расход
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="374px"
    >
      <v-form v-model="valid" ref="form" @submit.prevent="registerAnExpense()">
        <v-card>
          <v-card-title>
            <span class="text-h5">Регистрация расхода</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="description" color="teal" prepend-icon="mdi-pencil"
                    label="Описание (налог, аренда, материалы, др.)"
                    required
                    :rules="[v => !!v.length || 'Заполните это поле']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="sum" color="teal" prepend-icon="mdi-currency-rub"
                    label="Размер платежа"
                    type="number"
                    required
                    :rules="[v => !!v.length || 'Заполните это поле']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="pickerDate" color="teal" prepend-icon="mdi-calendar"
                        label="Дата платежа"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="teal lighten-1"
                      locale="RU-ru"
                      v-model="date"
                      @input="menu = false"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="teal" text type="submit">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import dateFilter from "@/filters/dateFilter";

export default {
  name: "RegisterAnExpense",

  props: ["text"],

  data: () => ({
    dialog: false,
    description: '',
    sum: '',
    date: '',
    menu: false,
    valid: true
  }),

  computed: {
    pickerDate: {
      get: function () {
        return dateFilter(this.date) || dateFilter(Date.now())
      }
    },
  },

  methods: {
    async registerAnExpense() {
      this.$refs.form.validate()
      if(!this.valid) return
      const date = this.date || dateFilter(Date.now(), 'ISO')

      const json = await this.authFetch('/pay/register-pay-or-expense-with-description-and-without-record', {
        description: this.description,
        sum: -+this.sum,
        date
      })
      this.description = ''
      this.sum = ''
      this.dialog = false
      this.$emit('update', json.pay)
    },

    allowedDates(val) {
      return (new Date(Date.now()) > new Date(val))
    }
  }
}
</script>

<style scoped>

</style>
