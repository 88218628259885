<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="timeModal"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click.stop="modal = true"
        :disabled="!dates.length"
        small width="160" height="36"
        :color="dates.length > 1 ? 'amber darken-1' : 'cyan darken-1'"
        class="mx-1 white--text"
        v-bind="attrs"
        v-on="on"
      >
        {{ dates.length > 1 ? "Создать окна" : "Создать окно" }}
      </v-btn>
    </template>

    <v-time-picker
      v-if="modal"
      v-model="timeModal"
      full-width
      format="24hr"
      :allowed-minutes="m => m % 5 === 0"
      color="teal lighten-1"
    >
      <v-spacer></v-spacer>

      <v-btn text color="teal" @click="modal = false">Cancel</v-btn>

      <v-btn v-if="!many" text color="teal" @click="createWindowHandler">OK</v-btn>

      <v-btn v-else text color="teal" @click="createManyWindowsHandler">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateWinsButton",
  data: () => ({
    timeModal: "",
    modal: false
  }),
  props: ["dates", "many", "setWinsLoading", "loadRecordList"],
  methods: {
    async createWindowHandler() {
      if (!this.timeModal.length) {
        this.$store.commit("setServerMessage", "Сначала выберите время")
        return
      }

      this.setWinsLoading(true)
      const [date] = this.dates
      const time = this.timeModal
      this.modal = false
      this.setWinsLoading(false)
      const json = await this.authFetch('/record/init-record', {date, time})
      if (json.status === 201) {
        await this.loadRecordList()
      }
    },

    async createManyWindowsHandler() {
      if (!this.timeModal.length) {
        this.$store.commit("setServerMessage", "Сначала выберите время")
        return
      }

      this.setWinsLoading(true)
      const time = this.timeModal
      const dates = this.dates
      this.modal = false
      await this.authFetch('/record/init-many-records', {dates, time})
      this.setWinsLoading(false)
      await this.loadRecordList()
    },
  }
}
</script>

<style scoped>

</style>
