<template>
  <v-card
    :loading="loading"
    class="mx-auto"
    width="374"
    elevation="0"
    style="border-radius: 0"
  >
    <v-container v-if="loading" style="width: 300px"></v-container>
    <v-container v-if="!loading">
      <v-row>
        <month-sum
          class="mb-5"
          :newPay="newPay"
          :year-month-prop="getYearMonth"
        >
        </month-sum>
      </v-row>

      <v-row justify="start">
        <v-btn
          @click="calendarType = 'simple'"
          width="100"
          style="border-radius: 0"
          elevation="0"
          :class="calendarType === 'simple' ? 'white--text' : ''"
          :color="calendarType === 'simple' ? 'teal lighten-1' : ''"
        >
          Дата
        </v-btn>

        <v-btn
          @click="calendarType = 'multiple'"
          width="100"
          style="border-radius: 0"
          elevation="0"
          :class="calendarType === 'multiple' ? 'white--text' : ''"
          :color="calendarType === 'multiple' ? 'teal lighten-1' : ''"
        >
          Даты
        </v-btn>

        <v-btn
          @click="calendarType = 'range'"
          width="100"
          style="border-radius: 0"
          elevation="0"
          :class="calendarType === 'range' ? 'white--text' : ''"
          :color="calendarType === 'range' ? 'teal lighten-1' : ''"
        >
          Период
        </v-btn>

      </v-row>

      <v-row justify="center">
        <v-date-picker full-width v-model="date" v-if="calendarType === 'simple'"
                       locale="ru-RU" first-day-of-week="1" color="teal lighten-1"
                       :events="functionEvents" event-color="red"
                       :disabled="winsLoading || loading"
                       :picker-date.sync="yearMonthSimple"
                       style="border-radius: 0 10px 0 0"></v-date-picker>
        <v-date-picker full-width v-model="dates" multiple v-if="calendarType === 'multiple'"
                       locale="ru-RU" first-day-of-week="1" color="teal lighten-1"
                       :disabled="winsLoading || loading"
                       :events="functionEvents" event-color="red"
                       :picker-date.sync="yearMonthMultiple"
                       style="border-radius: 0 10px 0 0"></v-date-picker>
        <v-date-picker full-width v-model="period" range v-if="calendarType === 'range'"
                       locale="ru-RU" first-day-of-week="1" color="teal lighten-1"
                       :disabled="winsLoading || loading"
                       :events="functionEvents" event-color="red"
                       :picker-date.sync="yearMonthRange"
                       style="border-radius: 0 10px 0 0"></v-date-picker>
      </v-row>

      <v-divider v-if="calendarType === 'simple'" class="my-5"></v-divider>

      <Wins
        v-if="calendarType === 'simple'"
        :date="date"
        :recordList="recordList"
        :winsLoading="winsLoading"
        :setTime="setTimeFromChildComponent"
        :loadRecordList="loadRecordList"
        :setWinsLoading="setWinsLoadingFromChildComponent"
      />

      <v-divider class="my-5"></v-divider>

      <v-row justify="center" class="my-5">
        <v-btn @click="recordHandler"
               small width="160" height="36" color="teal lighten-1"
               class="mx-1 white--text"
               :disabled="!time"
        >
          Записать клиента
        </v-btn>

        <CreateWinsButton
          :dates="getDates()"
          :loadRecordList="loadRecordList"
          :many="getDates().length > 1"
          :setWinsLoading="setWinsLoadingFromChildComponent"
        />
      </v-row>

      <v-divider class="my-5"></v-divider>

      <RecordList
        @pay-accepted="payAcceptedHandler"
        :recordList="recordList"
        :dates="getDates()"
        :winsLoading="winsLoading"
        :loadRecordList="loadRecordList"
        :setWinsLoading="setWinsLoadingFromChildComponent"
        :selectable="false"
      />

    </v-container>
    <NotificationRequest/>
  </v-card>
</template>

<script>
/* eslint-disable */
import dateFilter from "../../filters/dateFilter"
import CreateWinsButton from "../../components/CreateWinsButton"
import Wins from "../../components/Wins"
import RecordList from "../../components/RecordList"
import MonthSum from "@/components/MonthSum";
import NotificationRequest from "@/components/NotificationRequest";

export default {
  name: "Calendar",
  components: {NotificationRequest, MonthSum, RecordList, Wins, CreateWinsButton},
  data: () => ({
    calendarType: "simple",
    date: '',
    dates: '',
    period: '',
    time: "",
    loadedPastDates: [],
    recordList: [],
    winsLoading: false,
    loading: true,
    yearMonthSimple: "",
    yearMonthMultiple: "",
    yearMonthRange: "",
    newPay: ""
  }),

  computed: {
    getYearMonth() {
      if (this.calendarType === 'simple') return this.yearMonthSimple
      if (this.calendarType === 'multiple') return this.yearMonthMultiple || this.yearMonthSimple
      if (this.calendarType === 'range') return this.yearMonthRange || this.yearMonthSimple
    }
  },

  methods: {
    async loadRecordList() {
      this.winsLoading = true
      const now = dateFilter(Date.now(), "ISO")
      const loadedPastDates = this.loadedPastDates
      const json = await this.authFetch(
        '/record/load-record-list-and-windows',
        {now, loadedPastDates}
      )
      this.recordList = json.records
      this.recordList.sort(this.sortRecordListFunc)
      this.winsLoading = false
    },

    async loadRecordsForDates(dates) {
      this.winsLoading = true
      const json = await this.authFetch('/record/load-records-and-windows-for-dates', {dates})
      this.checkIncludesAndAdd_forArraysOfSimpleObjects(this.recordList, json.records)
      // this.recordList = this.recordList.concat(json.records)
      this.recordList.sort(this.sortRecordListFunc)

      this.checkIncludesAndAdd_notForObjects(this.loadedPastDates, dates)

      // this.loadedPastDates = this.loadedPastDates.concat(dates)
      this.winsLoading = false
    },

    initDates() {
      let date = this.$route.query.date || dateFilter(Date.now(), "ISO")
      this.date = date
      this.dates = [date]
      this.period = [date]
    },

    getDates() {
      if (this.calendarType === "simple") {
        if (this.date.length) {
          return [this.date]

        } else {
          return []
        }

      } else if (this.calendarType === "multiple") {
        return [...this.dates].sort()

      } else if (this.calendarType === "range") {
        if (this.period.length === 1) return [...this.period]
        let period = []
        let dates = [...this.period].sort()
        let date = dates[0]
        let endDate = dates[1]
        while (date <= endDate) {
          period.push(date)
          date = Date.parse(date) + 86400000 // magick number: milliseconds in 24h
          date = dateFilter(date, "ISO")
        }
        return period
      }
    },

    functionEvents(date) {
      if (dateFilter(date, "ISO") <= dateFilter(Date.now(), "ISO")) {
        return false
      }
      const events = []
      this.recordList.forEach(record => {
        if (record.date === date) {
          if (!record.open) {
            events.push("#00f")
          } else {
            events.push("#b1fc92")
          }
        }

      })
      if (events.length) return events
      return false
    },

    recordHandler() {
      if (!this.date || !this.time) return
      this.$store.commit("setDate", this.date)
      this.$store.commit("setTime", this.time)
      this.$router.push("/choose-category-master")
    },

    setTimeFromChildComponent(t) {
      this.time = t
    },

    setWinsLoadingFromChildComponent(bool) {
      this.winsLoading = bool
    },

    payAcceptedHandler(pay) {
      this.newPay = pay
    }
  },

  async mounted() {
    this.initDates()
    await this.loadRecordList()
    this.loading = false
  },

  watch: {
    date: function (newDate, oldDate) {
      if (dateFilter(newDate, "ISO") < dateFilter(Date.now(), "ISO") && !this.loadedPastDates.includes(newDate)) {
        this.loadRecordsForDates([newDate])
      }
    },
    dates: function (newDates, oldDates) {
      const newDate = newDates.filter(d => {
        if (!oldDates.includes(d)) {
          return d
        }
      })

      if (dateFilter(newDate, "ISO") < dateFilter(Date.now(), "ISO") && !this.loadedPastDates.includes(newDate)) {
        this.loadRecordsForDates(newDate)
      }
    },
    period: function (newPeriod, oldPeriod) {
      const period = this.getDates()

      const pastDatesInPeriod = period.filter(d => {
        if (dateFilter(d, "ISO") < dateFilter(Date.now(), "ISO") && !this.loadedPastDates.includes(d)) {
          return d
        }
      })
      if (pastDatesInPeriod.length) {
        this.loadRecordsForDates(pastDatesInPeriod)
      }
    }
  }
}
</script>

<style scoped>

</style>
