<template>
  <v-row justify="center">
    <div v-if="fcmToken">{{ fcmToken }}</div>
    <v-dialog
      v-model="dialogNotificationRequest"
      max-width="600px"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Push-уведомления?</span>
        </v-card-title>
        <v-card-text>
          {{
            $store.state.authenticated
              ? 'Нажми Хорошо'
              : 'К сожалению СМС-уведомления стали очень дорогим удовольствием. Поэтому мы просим Вас разрешить нам отправлять Вам push-уведомления, это очень нам поможет, спасибо!'
          }}
        </v-card-text>

        <div style="max-width: 300px; margin: 0 auto">
          <v-progress-linear
            v-if="loadingToken"
            v-model="loadingValue"
            color="deep-purple"
            buffer-value="0"
            stream
          ></v-progress-linear>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="dialogNotificationRequest = false"
          >
            Нет
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="permissionRequest"
          >
            Хорошо
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogNotificationIfDenied"
      max-width="600px"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Push-уведомления?</span>
        </v-card-title>
        <v-card-text>
          Прежде чем мы продолжим, пожалуйста, разрешите нам отправлять Вам уведомления, это очень нам поможет.
          К сожалению ранее Вы уже запретили это делать, поэтому для включения уведомлений, пожалуйста,
          следуйте инструкциям для своего браузера ниже.
          <p/>
          <div>
            <h3>Google Chrome (мобильная версия):</h3>
            <ol>
              <li><p><strong>Откройте меню:</strong></p>
                <p>Нажмите на три точки в верхнем правом углу браузера.</p>
                <p>В открывшемся меню можете нажать на значок
                  <v-icon>mdi-information-outline
                  </v-icon
                  > рядом с названием сайта и сразу включить уведомления, в этом случае следующие шаги не требуются</p>
              </li>
              <li><p><strong>Выберите "Настройки":</strong></p>
                <p>Прокрутите вниз и выберите "Настройки".</p>
              </li>
              <li><p><strong>Выберите "Уведомления":</strong></p>
                <p>Найдите и выберите "Уведомления".</p>
              </li>
              <li><p><strong>Ниже найдите раздел "Сайты":</strong></p>
                <p>В списке сайтов найдите наш сайт elenanovikova.ru и включите уведомления, установив галочку напротив названия сайта.</p>
              </li>
            </ol>
            <h3>Mozilla Firefox (мобильная версия):</h3>
            <ol>
              <li><p><strong>Откройте меню:</strong></p>
                <p>Нажмите на замочек рядом с названием сайта в адресной строке (для мобильной версии), либо на значок рядом с замочком (для компьютерной версии)</p>
              </li>
              <li><p><strong>Разрешите уведомления</strong></p>
                <p>Высавьте настройку "Уведомления" в значение "Разрешить"</p>
              </li>
            </ol>
            <h3>Яндекс.Браузер (мобильная версия):</h3>
            <ol>
              <li><p><strong>Откройте меню:</strong></p>

                <p>Нажмите на три горизонтальные линии в нижнем правом углу браузера.</p>

              </li>
              <li><p><strong>Выберите "Настройки":</strong></p>

                <p>Выберите "Настройки" из меню, и выполните шаги 3 и 4</p>
                <p>Либо в открывшемся меню можете нажать на значок
                  <v-icon>mdi-information-outline
                  </v-icon
                  >
                  рядом с названием сайта и сразу включить уведомления, в этом случае следующие шаги не требуются
                </p>

              </li>
              <li><p><strong>Выберите "Уведомления":</strong></p>

                <p>В разделе "Уведомления" выберите "Уведомления от сайтов"</p>

              </li>
              <li><p><strong>Найдите наш сайт "elenanovikova.ru":</strong></p>

                <p>Включите уведомления для нашего сайта</p>

              </li>
            </ol>
            <p>Учтите, что детали могут меняться в зависимости от конкретной версии браузера и операционной системы
              вашего мобильного устройства.</p></div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="dialogNotificationIfDenied = false"
          >
            Нет
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="getterPermissionStatusInterval = getPermissionStatus()"
          >
            Хорошо
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "NotificationRequest",

  data: () => ({
    dialogNotificationRequest: false,
    dialogNotificationIfDenied: false,
    permissionStatus: 'other',
    getterPermissionStatusInterval: '',
    fcmToken: '',
    loadingToken: false,
    loadingValue: 0,
  }),

  computed: {},

  methods: {
    async permissionRequest() {

      this.loadingToken = true
      this.loadingValue = 5
      const permission = await Notification.requestPermission()
      if (permission !== 'granted') {
        this.dialogNotificationRequest = false
        return
      }
      this.loadingValue = 20
      this.$store.commit('setServerMessage', 'Пожалуйста, немного подождите')
      await this.checkPermissionAndGetTokenAndSendTokenToServer()
      this.dialogNotificationRequest = false
    },

    async checkPermissionAndGetTokenAndSendTokenToServer() {
      // Проверка статуса разрешений на уведомления
      if (Notification.permission === "granted") {
        // Разрешения на уведомления есть
        this.loadingValue = 40
        const token = await this.firebaseGetToken()
        this.loadingValue = 70
        if (!token) return this.checkPermissionAndGetTokenAndSendTokenToServer()
        await this.sendTokenToServer(token)
      } else if (Notification.permission === "denied") {
        // Разрешения на уведомления отклоены
        const iOS = /iPhone|iPod|iPad/.test(navigator.userAgent)
        if (iOS) return
        this.dialogNotificationIfDenied = true
      } else {
        // Пользователь еще не принял решение или браузер не поддерживает уведомления
        this.dialogNotificationRequest = true
      }
    },

    getPermissionStatus() {
      this.dialogNotificationIfDenied = false
      return setInterval(() => {
        this.permissionStatus = Notification.permission
      }, 1000)
    },

    async sendTokenToServer(token) {
      const routeForSaveToken = this.$store.state.authenticated
        ? '/fcm-push/save-or-update-user-tokens'
        : this.$store.state.clientAuth
          ? '/fcm-push/save-or-update-subscriber-tokens'
          : false

      if (routeForSaveToken) {
        await this.authFetch(`${routeForSaveToken}`, {token}, false)
      }
    }
  },

  async mounted() {
    await this.checkPermissionAndGetTokenAndSendTokenToServer()
  },

  watch: {
    permissionStatus(newValue, oldValue) {
      if (oldValue === "denied" && newValue === "granted") {
        clearInterval(this.getterPermissionStatusInterval)
        this.checkPermissionAndGetTokenAndSendTokenToServer()
      }
    }
  },

  beforeDestroy() {
    if (this.getterPermissionStatusInterval) clearInterval(this.getterPermissionStatusInterval)
  }
}
</script>

<style scoped>

</style>
