<template>
  <v-card
    class="mx-auto"
    width="374"
    elevation="0"
    style="border-radius: 0"
    :style="windowWidth < 960 ? 'margin-top: -24px' : ''"
  >
    <div @click="payToolsActivator = !payToolsActivator">
      <small :class="payToolsActivator ? 'grey--text': 'teal--text'" style="transition-duration: 0.2s">
        {{ monthToString(yearMonthProp) }} {{ yearMonthProp.slice(0, 4) }} г.
      </small>
      <v-progress-linear
        :value="percentFromTarget(getMonthProfit(yearMonthProp))"
        striped
        :indeterminate="loading"
        color="teal lighten-4"
        height="28"
        rounded="small"
        :class="payToolsActivator ? 'elevation-0': 'elevation-2'"
      >
        <div style="position: absolute; left: 4px">
          <v-icon  :class="payToolsActivator ? 'grey--text': 'teal--text text--darken-2'" style="transition-duration: 0.2s">
            mdi-currency-rub
          </v-icon>
        </div>
        <span v-if="!loading" :class="payToolsActivator ? 'grey--text': 'teal--text text--darken-2'" style="transition-duration: 0.2s">
          <strong> {{ getMonthProfit(yearMonthProp) | sum }} </strong> ( + {{
            getMonthSum(yearMonthProp) | sum
          }} <span></span>/ - {{ getMonthExpense(yearMonthProp) | sum }} )
        </span>
        <div style="position: absolute; right: 4px">
          <v-icon
            :class="payToolsActivator ? 'black--text' : 'teal--text text--darken-2'"
            style="transition-duration: 0.2s"
            :style="payToolsActivator ? 'transform: rotate(180deg)' : ''"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </v-progress-linear>
    </div>
    <v-expand-transition>
      <v-card
        v-if="payToolsActivator"
        elevation="0"
        class="transition-fast-in-fast-out v-card--reveal"
      >
        <v-row justify="center">
          <v-btn
            max-width="374" min-width="320px" small color="success" dark class="mt-5"
            @click="$router.push(`/payments?yearMonth=${yearMonthProp}`)"
          >
            посмотреть детализацию за месяц
          </v-btn>
        </v-row>
        <v-row justify="center">
          <register-an-expense class="mt-5" @update="updatePays"></register-an-expense>
        </v-row>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import dateFilter from "@/filters/dateFilter"
import RegisterAnExpense from "@/components/RegisterAnExpense";

const MONTHS = [
  'ЯНВАРЬ',
  'ФЕВРАЛЬ',
  'МАРТ',
  'АПРЕЛЬ',
  'МАЙ',
  'ИЮНЬ',
  'ИЮЛЬ',
  'АВГУСТ',
  'СЕНТЯБРЬ',
  'ОКТЯБРЬ',
  'НОЯБРЬ',
  'ДЕКАБРЬ'
]

export default {
  name: "MonthSum",
  components: {RegisterAnExpense},
  props: ["dateISO", "yearMonthProp", "newPay"],

  data: () => ({
    loading: false,
    loading2: false,
    lastDate: '',
    pays: [],
    target: 77777,
    loadedMonths: [],
    payToolsActivator: false
  }),

  computed: {
    windowWidth: () => window.screen.width,
  },

  methods: {
    async getPaysForYearMonth(ISOYearMonth) {
      this.loading = true
      if (!ISOYearMonth) return
      let dates = [] // инициализация массива дат
      // пребразуем дату в объект даты
      const date = new Date(ISOYearMonth)
      // получаем начальный месяц
      let month = new Date(ISOYearMonth).getMonth()
      // получаем последний день месяца
      this.lastDate = dateFilter(new Date(date.getFullYear(), date.getMonth() + 1, 0), "ISO")
      // получаем точку останова цикла
      const targetMonth = new Date(new Date(ISOYearMonth).setMonth(new Date(ISOYearMonth).getMonth() - 1)).getMonth()

      while (month !== targetMonth) {
        // пушим дату
        dates.push(this.lastDate)
        // убавляем один день
        this.lastDate = dateFilter(new Date(this.lastDate).setDate(new Date(this.lastDate).getDate() - 1), "ISO")
        // отправляем месяц на проверку цикла
        month = new Date(this.lastDate).getMonth()
      }

      dates = dates.filter(d => {
        return !this.loadedMonths.includes(d.slice(0, 7))
      })

      if (dates.length) {
        this.loading = true
        const json = await this.authFetch('/pay/load-pays-for-dates', {dates})
        const paysToAdd = json.pays.filter(pay => !this.pays.map(p => p._id).includes(pay._id))
        this.pays = [...this.pays, ...paysToAdd].sort(this.sortPaysFunc)
        this.loadedMonths = this.iterableSetOfYearMonths()
        this.loading = false
      }
      this.loading = false
    },

    iterableSetOfYearMonths() {
      return Array.from(new Set(this.pays.map(p => p.date.slice(0, 7))))
    },

    getMonthSum(yearMonth) {
      return this.pays
        .filter(p => p.date.slice(0, 7) === yearMonth && p.sum > 0)
        .reduce((acc, p) => acc + +p.sum, 0)
    },

    getMonthExpense(yearMonth) {
      return this.pays
        .filter(p => p.date.slice(0, 7) === yearMonth && p.sum < 0)
        .reduce((acc, p) => acc + +p.sum, 0) * -1
    },

    getMonthProfit(yearMonth) {
      return this.pays
        .filter(p => p.date.slice(0, 7) === yearMonth)
        .reduce((acc, p) => acc + +p.sum, 0)
    },

    percentFromTarget(sum) {
      return Math.floor(sum / this.target * 100)
    },

    monthToString(yearMonth) {
      return MONTHS[+(yearMonth.slice(5)) - 1]
    },

    async updatePays(pay) {
      this.loading = true
      if (this.pays.map(p => p._id).includes(pay._id)) return
      this.pays.push(pay)
      this.pays.sort(this.sortPaysFunc)
      this.loading = false
    }
  },

  async mounted() {
    await this.getPaysForYearMonth(this.yearMonthProp)
  },

  watch: {
    async yearMonthProp(newValue) {
      await this.getPaysForYearMonth(newValue)
    },

    newPay(newPayValue) {
      if (this.pays.map(p => p._id).includes(newPayValue._id)) return
      this.pays.push(newPayValue)
      this.pays.sort(this.sortPaysFunc)
    },
  }
}
</script>

<style scoped>

</style>
